<template>
  <div class="home">
    <!-- 首页 -->
    <div class="top" ref="topM" id="topM">
      <div class="left" @click="back">
        <i class="el-icon-back"></i>
      </div>
      <div class="center">
        <span>{{ $route.meta.title }}</span>
      </div>
      <div class="right"  @click="openMenu">
        <!-- <span>{{ userInfo.nickname }}</span> -->
        <i class="el-icon-s-fold"></i>
      </div>
    </div>
    <div class="bottom">
      <!-- @mouseleave="showMenu = false" -->
      <div :class="showMenu ? 'show' : 'none'"  @mouseleave="showMenu = false">
        <MenuVue></MenuVue>
      </div>
      <div class="mainContent">
        <router-view @click="back"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVue from '../components/Menu.vue';
import TopVue from '../components/Top.vue';

export default {
  name: 'Home',
  data() {
    return {
      userInfo: null,
      showMenu: false,
      planInfo: null
    }
  },
  methods: {
    back(val) {
      this.planInfo = JSON.parse(localStorage.getItem('planInfo'));
      // this.$router.go(-1);
      // console.log(this.planInfo);
      let path = this.$route.path;
      if (path === '/home/evaluation'|| path === '/home/personal' || path === '/home/recordManagement') {
        this.$router.push('/home/index');
      } else if (path === '/home/gauge' || path === '/home/planDetail' ) {
        this.$router.push('/home/evaluation');
      } else if (path === '/home/guidelines' || path === '/home/nowReport') {
        console.log(this.planInfo.isMerge);
        if(this.planInfo.isMerge === 1 || this.planInfo.isMerge === '1'){
          this.$router.push('/home/evaluation');
        } else {
          this.$router.push('/home/gauge');
        }
      } else if (path === '/home/report') {
        this.$router.push('/home/index');
      } else if (path === '/home/viewReport' || path === '/home/answerRecord') {
        this.$router.push('/home/report');
      } else if (path === '/home/basicInformation' || path === '/home/changeTel' || path === '/home/secret' || path === '/home/pwd') {
        this.$router.push('/home/personal');
      }else if (path === '/home/registerUser') {
        this.$router.push('/home/index');
      } else if ( path === '/home/consultDetails') {
        this.$router.push('/home/consulting');
      }else if ( path === '/home/appointmentDetails') {
        this.$router.push('/home/consultDetails');
      } else if( path === '/home/record' || path === '/home/myRecord' || path === '/home/consulting') {
        this.$router.push('/home/recordManagement');
      }
      // else if ( path === '/home/') {
      //   this.$router.push('/home/');
      // }
      //  else if (path === '/home/information' || path === '/home/reportList') {
      //   this.$router.push('/home/record');
      // }

    },
    openMenu() {
      this.showMenu = !this.showMenu;
      // console.log(111)
    }
  },
  created() {
    // this.$forceUpdate();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // this.planInfo = JSON.parse(localStorage.getItem('planInfo'));
    // console.log(this.planInfo)
  },
  components: {
    MenuVue, TopVue
  }
}
</script>
<style lang="scss" scoped>


.home {
  position: relative;
}

.show {
  width: 130px;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  display: block;
  animation-name: move;
   box-shadow: 0px 2px 4px 0px rgba(103, 102, 102, 0.25);
  /* 持续时间 */
  animation-duration: 0.2s;
  background: white;
  // height: calc(100% - 80px);
  height: 100%;
  z-index: 100;
  // overflow-x: hidden;
}

.none {
  display: none;
}


@keyframes move {
  0% {
    transform: translate(150px, 0);
    background: white;
  }

  100% {
    transform: translate(0, 0px);
    background: white;

  }
}


.top{
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.25);
  // box-shadow: 10px 10px 10px 10px black;
  z-index: 33333;
  .right{
    cursor: pointer;
  }
  
}
.bottom{
  // margin-top: 2px;
  box-sizing: border-box;
  background-image: url('../assets/home/侧边导航备份\ 2.jpg');
  background-size: cover;
}


@media screen and (min-width: 751PX) {
  .home {
    font-size: 20px;
    height: 100vh;
    width: 100%;
    //  background-image: url('../assets/home/backgroundImage.png');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-attachment: fixed;

    .top {
      width: 100%;
      height: 80px;
      // background: var(--custom-color);
      background: var(--custom-color);
      // box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      box-sizing: border-box;
      padding: 0px 20px;
      // div{
      //   width: 30%;
      //   text-align: center;
      // }

      .left {
        font-weight: 800;
        cursor: pointer;

        i {
          font-weight: 800;
          font-size: 28px;
        }
      }
    }
  }

  .bottom {

    overflow-y: scroll;
    // background-image: url('../assets/home/backgroundImage.png');
    background-color: var(--theme-color);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

    .mainContent {
      height: calc(100vh - 85px);
      box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
      width: 900px;
      background: white;
      // box-shadow: 2px 2px 2px 2px black;
      border: 1px solid #EEEEEE;
      margin: auto;

    }
  }
}

@media screen and (max-width: 750PX) {
  .home {
    font-size: 16px;
    width: 100%;

    .top {
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
      height: 45px;
      background: var(--custom-color);
      // box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      //  div{
      //   width: 30%;
      //   // text-align: center;
      // }

      .left {
        // line-height: 45px;
        cursor: pointer;

        i {
          font-weight: 800;
          font-size: 28px;
        }
      }

      .right {
        // line-height: 45px;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-right: 5px;
          font-size: 16px;
          width: 60px;
          text-align: right;
          box-sizing: border-box;
        }

        i {
          // font-weight: 800;
          font-size: 24px;
        }
      }
    }

    .bottom {
      height: calc(100vh - 45px);
      overflow-y: scroll;

      .mainContent {
        width: 100%;
        background: white;
        margin: auto;

      }
    }
  }
}
.top{
  .left{
    // width: 60px;
  }
  .left,.center, .rigth{
    // width: 33.33%;
    box-sizing: border-box;
    // text-align: center;
  }
  .center{
    text-align: center;
  }
}
.logo{
	// img{
	// 	line-height: 100px;
	// }
	display: flex;
	justify-content: center;
	align-items: center;
}
@media print {
  .top{
    display: none;
  }
}
</style>
